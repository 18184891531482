import service from '../utils/request'

// 查询主机下的视频
export function findVideoByHost(hostId, page, pageSize) {
  return service({
    method: 'get',
    url: `/video/findByHost`,
    params: {
      hostId,
      page,
      pageSize,
    },
  })
}
// 查询任务下的视频
export function findVideoByTask(taskId) {
  return service({
    method: 'get',
    url: `/video/findByTask`,
    params: {
      taskId,
      page: 1,
      pageSize: 10,
    },
  })
}

// 分配视频到任务
export function distributionTask(ids, taskId) {
  return service({
    method: 'put',
    url: `/video/distribution`,
    params: {
      ids,
      taskId,
    },
  })
}
