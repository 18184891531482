<template>
  <div style="width: 100%; height: 100%">
    <iframe v-if="isShow === 1" :src="url" frameborder="1" style="width: 100%; height: 100%; padding: 0; margin: 0"></iframe>
    <video v-else-if="isShow === 2" class="demo-video" style="" ref="player" muted autoplay controls="controls"></video>
  </div>
</template>

<script>
import flvjs from 'flv.js'
export default {
  data() {
    return {
      isShow: 1,
    }
  },
  props: ['videoId', 'url', 'videoType'],
  watch: {
    videoId: {
      handler() {
        console.log('修改监听')
        if (!this.url) return
        if (this.videoType === 1) {
          this.isShow = 1
        } else if (this.videoType === 2) {
          return
          // this.isShow = 2
          // this.destoryVideo()
          // this.getVideos()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getVIdeos() {
      if (flvjs.isSupported()) {
        this.$nextTick(() => {
          let video = this.$refs.player
          if (video) {
            this.$refs.player = flvjs.createPlayer({
              type: 'flv',
              isLive: true,
              url: `ws://127.0.0.1:15201/rtsp/${this.videoId}/?url=${this.url}`,
              //   url: `ws://120.238.35.169/:15201/rtsp/${this.videoId}/?url=${this.url}`,
              // url: `ws://192.168.66.129:15201/rtsp/${this.videoId}/?url=${this.url}`
            })
            this.$refs.player.attachMediaElement(video)

            try {
              this.$refs.player.load()
              this.$refs.player.play()
            } catch (error) {
              console.log('报错了', error)
            }
          }
        })
      }
    },
    destoryVideo() {
      console.log('销毁断流方法')
      this.$refs.player.pause()
      this.$refs.player.unload()
      this.$refs.player.detachMediaElement()
      this.$refs.player.destroy()
      this.$refs.player = null
    },
  },
  created() {},
  mounted() {
    // console.log(this.videoId, this.url, this.videoType)
  },
}
</script>

<style scoped lang="scss">
.demo-video {
  width: 100%;
  height: 100%;
}
</style>
